
// Vue reactivity
import { computed, } from 'vue';

// icons
import { } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonGrid, IonRow, IonCol,
        IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonRefresher, IonRefresherContent, } from '@ionic/vue';

import PageHeader from "@/components/PageHeader.vue";
import ImageSlides from '@/components/slides/ImageSlides.vue';

// API services
import PostService from '@/services/PostService';
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'PostDetailPage',
  components: { IonPage, IonContent, IonGrid, IonRow, IonCol,
                IonSpinner, IonItem, IonLabel, IonChip, IonIcon,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonRefresher, IonRefresherContent, ImageSlides, PageHeader, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const currPostId = route.params.id; // post ID

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingAppPublicData);
    const post = computed(() => store.getters.getPostById(currPostId));

    // 2. methods or filters
    const parentPath = route.params.parentPath; // may be from user posts page
    const { formatDate } = utils();
    
    const fetchLatestPost = (postId: any, e: any = null) => {
      PostService.getPostById(postId).then(res => {
        store.commit('updatePost', res);
      });
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons

      // variables
      post, currPostId, parentPath, loading,

      // methods
      t, fetchLatestPost, formatDate,
    }
  },
}
