import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53f6a79e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_refresher_content = _resolveComponent("ion-refresher-content")!
  const _component_ion_refresher = _resolveComponent("ion-refresher")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_image_slides = _resolveComponent("image-slides")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        parentPath: $setup.parentPath ? $setup.parentPath : '/posts'
      }, null, 8, ["parentPath"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_refresher, {
            slot: "fixed",
            onIonRefresh: _cache[0] || (_cache[0] = ($event: any) => ($setup.fetchLatestPost($setup.currPostId, $event)))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_refresher_content)
            ]),
            _: 1
          }),
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  ($setup.post)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            "size-xs": "12",
                            "size-md": "8",
                            "size-xl": "9"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_header, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_title, { class: "post-title ion-text-center" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.post.title), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_card_subtitle, { class: "ion-text-center" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.formatDate($setup.post.createdAt, "YYYY-MM-DD")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_card_content, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", {
                                    class: "post-content",
                                    innerHTML: $setup.post.contentHtml
                                  }, null, 8, _hoisted_2)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_col, {
                            class: "ion-text-center",
                            "size-xs": "12",
                            "size-md": "4",
                            "size-xl": "3"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("img", {
                                class: "post-image",
                                src: $setup.post.imageLink
                              }, null, 8, _hoisted_3),
                              ($setup.post.photoLinks && $setup.post.photoLinks.length >= 2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_component_image_slides, {
                                      imageLinks: $setup.post.photoLinks,
                                      minSlidesPerView: 3,
                                      size: "small",
                                      hideEyeIcon: true,
                                      defaultCaption: $setup.post.title
                                    }, null, 8, ["imageLinks", "defaultCaption"])
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}